import React from "react";

export const weddingDate = '2022-08-13T16:00:00';

export const mainImage = {
    src: require('../images/mainImage.jpg').default,
    alt: "Zuzanna i Mateusz"
};

export const countdownImage = {
    src: require('../images/wideImage.jpg').default,
    alt: "Zuzanna i Mateusz"
};

export const brideImage = {
    src: require('../images/bride.jpg').default,
    alt: "Zuzanna"
};

export const groomImage = {
    src: require('../images/groom.jpg').default,
    alt: "Mateusz"
};

export const rebus = {
    src: require('../images/rebus.png').default,
    alt: "Rebus"
};

export const titleSectionText = {
    title: "Save the date",
    city: "Częstochowa",
    country: "Polska",
    brideName: "Zuzanna",
    groomName: "Mateusz",
    dateText: "13 sierpnia 2022"
};

export const coupleSectionText = {
    hello: "Witamy!",
    paragraph: "Zapraszamy Was do wspólnego świętowania!",
    brideName: "Zuzanna",
    brideDesc: "Wierzy, że można osiągnąć prawie wszystko, pod warunkiem ciężkiej pracy i obecności właściwych ludzi przy boku. Studentka kierunku lekarskiego na uniwersytecie medycznym we Wrocławiu. Przyszła lekarka i bizneswoman.",
    groomName: "Mateusz",
    groomDesc: "Programista, absolwent Politechniki Wrocławskiej. Uwielbia podróże i kuchnię orientalną. Tak jak większość programistów, interesuje się nowinkami technologicznymi i motoryzacją."
};

export const countdownSectionText = {
    header: "Nasz wielki dzień",
    paragraph: "Cieszymy się i jesteśmy podekscytowani, że będziemy mogli ten dzień spędzić z Wami - nabliższą rodziną i przyjaciółmi. Dziękujemy, że odwiedziliście naszą stronę internetową!",
    beforeDate: "Jeszcze tylko chwilka...",
    afterDate: "...I będziemy małżeństwem!"
};

interface Location {
    name: string;
    address: string;
    link: string;
}
interface MyImage {
    src: string;
    alt: string;
};

interface EventInfo {
    name: string;
    date: string;
    text: string;
    location: Location;
    image: MyImage;
};

interface EventsSection {
    header: string;
    events: EventInfo[];
};

export const eventsSection: EventsSection = {
    header: 'Przyjęcie weselne',
    events: [
        {
            name: "Ślub",
            date: '2022-08-13T16:00',
            text: "Uroczysta ceremonia ślubna odbędzie się w naszej wspólnej parafii.",
            location: {
                name: "Parafia św. Franciszka z Asyżu w Częstochowie",
                address: "Luba 26, Częstochowa",
                link: "https://goo.gl/maps/S53cNbQ3c4Trx4x9A",
            },
            image: {
                src: require('../images/rings.jpg').default,
                alt: "Zuzanna i Mateusz"
            }
        },
        {
            name: "Obiad",
            date: '2022-08-13T18:00:00',
            text: "Po uroczystości zaślubin serdecznie zapraszamy na weselny obiad, który rozpocznie nasze wesele.",
            location: {
                name: "Studnia Centrum Przyjęć Okolicznościowych",
                address: "Jaskrowska 14/20, Częstochowa",
                link: "https://goo.gl/maps/dX3TXVJhwK9Q8e2z8",
            },
            image: {
                src: require('../images/party_place.jpg').default,
                alt: "Zuzanna i Mateusz"
            }
        },
        {
            name: "Życzenia",
            date: '2022-08-13T18:45:00',
            text: "Po obiedzie będzie czas na wymianę kilku ciepłych słów z każdym z gości.",
            location: {
                name: "Studnia Centrum Przyjęć Okolicznościowych",
                address: "Jaskrowska 14/20, Częstochowa",
                link: "https://goo.gl/maps/dX3TXVJhwK9Q8e2z8",
            },
            image: {
                src: require('../images/wishes.jpg').default,
                alt: "Zuzanna i Mateusz"
            }
        },
        {
            name: "Pierwszy taniec",
            date: '2022-08-13T19:15:00',
            text: "Tanecznym krokiem, już jako nowożeńcy, rozpoczniemy wspólną zabawę na parkiecie.",
            location: {
                name: "Studnia Centrum Przyjęć Okolicznościowych",
                address: "Jaskrowska 14/20, Częstochowa",
                link: "https://goo.gl/maps/dX3TXVJhwK9Q8e2z8",
            },
            image: {
                src: require('../images/dance.jpg').default,
                alt: "Zuzanna i Mateusz"
            }
        },
        {
            name: "Tort",
            date: '2022-08-13T22:30:00',
            text: "",
            location: {
                name: "Studnia Centrum Przyjęć Okolicznościowych",
                address: "Jaskrowska 14/20, Częstochowa",
                link: "https://goo.gl/maps/dX3TXVJhwK9Q8e2z8",
            },
            image: {
                src: require('../images/cake.jpg').default,
                alt: "Zuzanna i Mateusz"
            }
        },
        {
            name: "Poprawiny",
            date: '2022-08-14T14:00:00',
            text: "Serdecznie zapraszamy wszystkich naszych gości na poprawiny drugiego dnia.",
            location: {
                name: "Studnia Centrum Przyjęć Okolicznościowych",
                address: "Jaskrowska 14/20, Częstochowa",
                link: "https://goo.gl/maps/dX3TXVJhwK9Q8e2z8",
            },
            image: {
                src: require('../images/second_day.jpg').default,
                alt: "Zuzanna i Mateusz"
            }
        }
    ]
}

const Consts = () => {
    return (<div></div>);
}

export default Consts;